import request from '@/utils/request'

const paymentApi = {
  trade_payment_list: '/trade/payment/',
  trade_payment_create: '/trade/payment/',
  trade_payment_update: '/trade/payment/',
  trade_payment_delete: '/trade/payment/',
  trade_payment_archived: '/trade/payment_archived/'
}

/**
 * 列表
 */
export function payment_list (parameter) {
  return request({
    url: paymentApi.trade_payment_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function payment_create (parameter) {
  return request({
    url: paymentApi.trade_payment_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function payment_update (parameter, corporation_id) {
  return request({
    url: paymentApi.trade_payment_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function payment_delete (corporation_id) {
  return request({
    url: paymentApi.trade_payment_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function payment_archived (parameter) {
  return request({
    url: paymentApi.trade_payment_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
